import SearchForm from '../lib/search_form';
import BusSearchForm from '../lib/bus/search_form';


class DirectionPage {
  constructor(page) {
    this.page = page
    if (this.page.hasClass('bus_direction')) {
      this.searchForm = new BusSearchForm(this.page.find('#tickets-search-form'))
    }else{
      this.searchForm = new SearchForm(this.page.find('#tickets-search-form'))
    }
  }
}

$(() => {
  let page = $('body.direction, body.bus_direction, body.intercity_plus')
  if (page){
    new DirectionPage(page)
  }
})

export default DirectionPage
