import {Popup} from '../popup'
import Validation from '../validation'
import Submiter from '../submiter'
import SubMenu from '../sub-menu'

var ProfileUpdateForm = function(){
  this.form         = $('.js-profile-update');
  this.result       = $('.js-profile-result');
  this.submitButton = $('.js-update-profile-submit');

  this.initializeSubmit();
  this.initCheckboxes();
  this.initMask();
}

ProfileUpdateForm.prototype.formValid = function(){
  var self = this;
  var email_format = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
  var email = this.form.find('[name="user[email]"]').val();
  var name_format = /^[A-Za-zА-Яа-яїЇІієЄЭэЙйъёь\- ]+$/;
  var phone_number_input = this.form.find('[name="user[phone]"]');
  var phone = phone_number_input.val();
  var bonus_program = this.form.find('[name="user[bonus_program]"]').prop("checked");
  var first_name = this.form.find('[name="user[first_name]"]').val();
  var last_name = this.form.find('[name="user[last_name]"]').val();

  if (email == ''){
    new Popup('error', I18n.t('user_email_required')).show();
    return false;
  }
  if (!email_format.test(email)){
    new Popup('error', I18n.t('email_email')).show();
    return false;
  }
  if (phone.length != phone_number_input[0].minLength){
    new Popup('error', I18n.t('phone_valid')).show();
    return false;
  }
  if (first_name != '' && !name_format.test(first_name)){
    new Popup('error', I18n.t('firstname_lettersonly')).show();
    return false;
  }
  if (last_name != '' && !name_format.test(last_name)){
    new Popup('error', I18n.t('lastname_lettersonly')).show();
    return false;
  }
  if(bonus_program) {
    if(phone == '' || first_name == '' || last_name == '') {
      new Popup('error', I18n.t('bonus_program')).show();
      return false;
    }
  }
  if($('#student').is(':checked')){
    var passport   = new Validation('passport', self.form.find('[name="user[student]"]'));
    if (!passport.isValid()){
      passport.showError();
      return indicator = false;
    }
  }
  
  return true;
}

ProfileUpdateForm.prototype.initCheckboxes = function(){
  $('#student').on('change', function(){
    $('#student-block').toggleClass('d-none');
  });
  
  $('#password-check, #password-confirm-check, #current-password-check').change(function(){
    var text_input = $(this).parent().find('input.txtinput');
    if(this.checked) {
      text_input.attr('type', 'text')
    } else {
      text_input.attr('type', 'password')
    }
  })
}


ProfileUpdateForm.prototype.initializeSubmit = function(){
  var self = this;
  this.submitButton.on('click', function(event){
    if(self.formValid()){
      self.handleStudentAttribute();

      new Submiter(self.form).done(function(data){
        self.result.html(data.result.html).show();
        self.initCheckboxes();
        self.form = $('.js-profile-update');

        if (data.result.message){
          new Popup('attention', data.result.message).show();
        }else {
          new Popup('attention', I18n.t('profile_updated')).show();
        }
        new SubMenu();
        self.initMask();
      });
    }
  });
}

ProfileUpdateForm.prototype.handleStudentAttribute = function(){
  if (!$('#student').is(':checked')) {
    $('[name="user[student]"]').val('');
  }
}

ProfileUpdateForm.prototype.initMask = function(){
  var self = this;
  var phone_number = self.form.find('[name="user[phone_number]"]');
  $(phone_number).inputmask({ 
                              mask: phone_number.data('mask'), 
                              autoUnmask: true
                            });
}



export default ProfileUpdateForm