import SearchForm from '../lib/search_form'
import BusSearchForm from '../lib/bus/search_form'


class MainPage {
  constructor(main_page) {
    this.main_page = main_page

    switch(true)  {
      case this.main_page.hasClass('bus'):
        this.searchForm = new BusSearchForm(this.main_page.find('#tickets-search-form'))
        break;
      case this.main_page.hasClass('index'):
        this.searchForm = new SearchForm(this.main_page.find('#tickets-search-form'))
        break;
      }
  }

}

$(() => {
  let main_page = $('body.index.main, body.bus.main')
  if (main_page){
    new MainPage(main_page)
  }
})

export default MainPage
