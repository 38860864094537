import {Popup} from './popup'


var Validation = function(name, field, error_key){
  this.field        = $(field);
  this.name         = name;
  this.error_key    = error_key;
  this.value        = this.field.val() && this.field.val().replace(/^\s+|\s+$/gm,'');
  this.errors       = [];
  this.nameRegex      = /^[a-z\u0400-\u04FF'\s`\.\-]+([-\s]?[a-z\u0400-\u04FF'])*$/gi;
  this.passportRegex  = /^[а-я]{2}\d{8}$/gi;
  this.phoneRegex  = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[(]?[0-9]{0,2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/gi;
  this.emailRegex     = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/gi;

  this.showError = this.showError.bind(this);
};
Validation.prototype.isValid = function(check_value){
  return this[this.name](check_value);
};
Validation.prototype.addError = function(factor){
  this.errors.push(I18n.t(this.errorKey(factor)));
};
Validation.prototype.errorKey = function(factor){
  return this.error_key || 'document.validation.'+this.name+'.'+factor;
};
Validation.prototype.showError = function(){
  if(this.errors.length > 0){
    new Popup('error', this.errors[0]).show();
    this.field.css('border-color', '#d56950').focus();
    this.field.on('change', function(event){
      $(event.target).css('border-color', '#ced3d8');
    });
  }
};

Validation.prototype.firstname = function(last_name_passed){
  if(!this.value){
    this.addError('presence');
    return false;
  }
  if(!this.nameRegex.test(this.value)){
    this.addError('format');
    return false; 
  }
  if(this.value.length < 2){
    this.addError('format');
    return false; 
  }

  if(this.checkExclusions(this.name, this.value)) return true;

  let valueSet = new Set(this.value.toLowerCase().split(''));
  
  if(valueSet.size < 2){
    this.addError('one_char');
    return false; 
  }

  if(this.findVowelsConsonat(valueSet)){
    this.addError('vowels_and_consonant');
    return false; 
  }

  if(last_name_passed && this.value.toLowerCase() == last_name_passed.toLowerCase().replace(/^\s+|\s+$/gm,'')){
    this.addError('same_value');
    return false; 
  }

  if(this.findDuplicates(this.value, 4, true)){
    this.addError('duplicates');
    return false; 
  }

  return true;
};

Validation.prototype.lastname = function(first_name_passed){
  return this.firstname(first_name_passed);
};

Validation.prototype.passport = function(){
  if(!this.value){
    this.addError('presence');
    return false;
  }
  if(!this.passportRegex.test(this.value)){
    this.addError('presence');
    return false;
  }

  return true;
};

Validation.prototype.phone = function(){
  if(!this.value){
    this.addError('presence');
    return false;
  }
  if(!this.phoneRegex.test(this.value)){
    this.addError('format');
    return false;
  }
  return true;
};

Validation.prototype.birthday = function(){
  if(!this.value){
    this.addError('presence');
    return false;
  }
  return true;
};

Validation.prototype.email = function(){
  if(!this.value){
    this.addError('presence');
    return false;
  }
  if(!this.emailRegex.test(this.value)){
    this.addError('format');
    return false;
  }

  return true;
};

Validation.prototype.age = function(){
  if(!this.value){
    this.addError('presence');
    return false;
  }

  return true;
};

Validation.prototype.time = function(){
  if(!this.value){
    this.addError('presence');
    return false;
  }

  return true;
};

Validation.prototype.default = function(){
  var input_validation_data = this.field.data("validate");
  if( input_validation_data && input_validation_data.presense && !this.value){
    this.addError('presence');
    return false;
  }

  return true;
};

Validation.prototype.getAllSubstrings = function(str, size, strict) {
  var i, j, result = [];
  size = (size || 0);
  for (i = 0; i < str.length; i++) {
    if(strict){
      if(str.length > i+size-1) result.push(str.slice(i, i+size));
    }else{
      for (j = str.length; j-i>=size; j--) {
        result.push(str.slice(i, j));
      }
    }
  }

  return result;
};

Validation.prototype.findDuplicates = function(str, size, strict){
  var arr = this.getAllSubstrings(str, size, strict);
  var sorted_arr = arr.slice().sort();
  var results = [];
  for (var i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] == sorted_arr[i]) {
          results.push(sorted_arr[i]);
      }
  }

  return results.length;
};


Validation.prototype.findVowelsConsonat = function(valueSet){
  var latinVowels     = new Set(['a', 'e', 'i', 'o', 'u', 'y']);
  var latinConsonant  = new Set(['b', 'c', 'd', 'f','g','h', 'j','k','l', 'm', 'n','p', 'q', 'r', 's', 't', 'v', 'w', 'x', 'z']);
  var cyrilicVowels   = new Set(['а', 'е', 'є', 'и', 'і', 'ї', 'о', 'у', 'ю', 'я', 'ё', 'э']);
  var cyrilicConsonant= new Set(['б','в', 'г', 'ґ', 'д', 'ж', 'з', 'й', 'к', 'л', 'м', 'н', 'п', 'р', 'с', 'т', 'ф', 'х', 'ц', 'ч', 'ш', 'щ', 'ь', 'ъ', 'ы']);

  var intersectlatinVowels     = new Set([...valueSet].filter(i => latinVowels.has(i)));
  var intersectlatinConsonant  = new Set([...valueSet].filter(i => latinConsonant.has(i)));
  var intersectcyrilicVowels   = new Set([...valueSet].filter(i => cyrilicVowels.has(i)));
  var intersectcyrilicConsonant= new Set([...valueSet].filter(i => cyrilicConsonant.has(i)));

  return !(intersectlatinVowels.size && intersectlatinConsonant.size || intersectcyrilicVowels.size && intersectcyrilicConsonant.size);
};

Validation.prototype.checkExclusions = function(name, value){
  var exclusions ={
    lastname: ['колоколов','колоколова','ростроста','рострост','шароваров','шароварова'],
    firstname: ['ія','ия']
  }

  return (exclusions[name] && exclusions[name].includes(value.toLowerCase()));
}

export default Validation