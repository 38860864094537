class Faq {
  constructor(container) {
    this.container = container;
    // this.rotatePlus = rotatePlus;
    this.setupAccordion();
  }

  setupAccordion() {
    this.container.find(".js-faq-question").on("click", (ev) => {
      let faqTitle = $(ev.target);
      let faqContent = faqTitle.next(".js-faq-answer");
      faqContent.slideToggle();
    });
  }
}

$(() => {
  let faqContainer = $(".js-faq-container");
  if (faqContainer.length) {
    new Faq(faqContainer);
  }
});

// let rotatePlus = document.querySelector(".tooglePlus");
// rotatePlus.addEventListener("click", () => {
//   rotatePlus.classList.toggle("rotateX");
// });
