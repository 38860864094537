// import $ from 'jquery'
// import validate from 'jquery-validation'
// import datepicker from 'bootstrap-datepicker'

import datepicker_locale from '../i18n-js/datepicker'
import RailwaySearchNotification from '../lib/railway_search_notification'
import {ConfirmPopup} from './popup'
import Submiter from './submiter'


class SearchForm {
  constructor (searchForm) {
    this.form = searchForm
    this.datepickers = []
    this.autocompleteCache = {}

    this.setInputs()

    this.setupValidation()
    this.setupDatepickers()
    this.setupDatepickerMonthCount()
    this.setupAutocomplete()
    this.setupDayPartsEvents()
    this.setupDestinantionsToggle()
    //TODO: -delete setupTabs after checking if it is redundant
    // this.setupTabs()
    this.setDefaults()
    this.onRoundtripClick();

    this.rw_search_notification = new RailwaySearchNotification()
  }

  setInputs() {
    this.autocompleteUrl  = '/stations/complete_with_city'
    this.searchUrl        = '/search'
    this.fromName         = this.form.find('.js-from-name');
    this.fromCode         = this.form.find('[name=from]');
    this.toName           = this.form.find('.js-to-name');
    this.toCode           = this.form.find('[name=to]');
    this.date             = this.form.find('[name=date]');
    this.roundtrip        = this.form.find('#round-trip');
    this.roundtripLabel   = this.roundtrip.siblings('label[for='+this.roundtrip.attr('id')+']');
  }

  setDefaults() {
    if(scenario.isRoundtrip()) this.roundtripLabel.click();
  }

  onRoundtripClick() {
    var self = this;

    this.roundtrip.on('click', function(event){
      var checkbox = $(event.target);
      var isChecked = checkbox.is(':checked');

      if(scenario.basketHasItems()){
        new ConfirmPopup(I18n.t('decline_popup.cancel_basket'),function(answer){
          if(answer === 'yes'){
            new Submiter($('.js-clear-basket')).done(function(data){
              scenario.setRoundtrip(isChecked);
            });
          }else{
            checkbox.attr('checked', !isChecked);
            self.roundtripLabel.click();
            return;
          }
        });
      }else{
        scenario.setRoundtrip(isChecked);
      }
    });
  }

  setupValidation() {
    var self = this;

    this.validator = this.form.validate()
    this.form.on('submit', (ev)=>{
      ev.preventDefault()

      if (!this.rw_search_notification.messageEmpty()) {
        return this.rw_search_notification.showMessage()
      }

      if (this.valid()) {
        this.setScenario()
        window.location = `${$('#langMenuButton').data('lang-url')}${self.searchUrl}?${this.searchParams()}`;
      }
    })
  }
  setScenario() {
    if(/^22/.test(this.fromCode.val())){
      scenario.Local();
    }else{
      scenario.International();
    }
  };
  searchParams(){
    return $.param({
      from:       this.fromCode.val(),
      to:         this.toCode.val(),
      date:       this.date.val(),
      roundtrip:  this.roundtrip.val()
    });
  }

  searchParams(){
    return $.param({
      from:       this.fromCode.val(),
      to:         this.toCode.val(),
      date:       this.date.val(),
      roundtrip:  this.roundtrip.val(),
    })
  };

  valid () {
    if(!this.fromCode.val()){
      this.validator.showErrors({
        'from_name': I18n.t('search_form.validation.from_code_presence')
      })
      return false;
    }

    if(!this.toCode.val()){
      this.validator.showErrors({
        'to_name': I18n.t('search_form.validation.to_code_presence')
      })
      return false;
    }
    
    if(this.fromCode.val() == this.toCode.val()){
      this.validator.showErrors({
        'from_name': I18n.t('search_form.validation.codes_equivalent')
      })
      return false;
    }

    return true;
  }

  setupDatepickers() {
    $.datepicker.setDefaults(datepicker_locale[I18n.locale])

    this.form.find('.datepicker').each((i, el)=>{
      let calendar_input = $(el);
      let calendar = calendar_input.datepicker({
        dateFormat: 'dd.mm.yy',
        firstDay: 1,
        maxDate: '+89D',
        yearSuffix: '', 
        minDate: 0,
        prevText: '<',
        nextText: '>',
        customFooter: false,
        onSelect: (dateText, datepicker)=>{
          let input = datepicker.input
          this.daytimeChanged(datepicker)

          if (this.datepickers[0].attr('id') == datepicker.id) {
            // this.datepickers[1].datepicker('option', 'minDate', new Date(datepicker.selectedYear, datepicker.selectedMonth, datepicker.selectedDay))
            // this.daytimeChanged(this.datepickers[1])
          }

        },
        afterRender: (datepicker) => {
          $(datepicker.dpDiv).addClass('notranslate');
          $(datepicker.dpDiv).find('input[type=checkbox]').on('change', (ev)=>{
            $('.daytimes input[type=checkbox]').each((i,el)=>{
              let calendarCheckbox = $(el)
              let text = calendarCheckbox.siblings('label').text().trim().toLocaleLowerCase()
              let relatedInput = datepicker.input.siblings('.'+calendarCheckbox.attr('value'))
              relatedInput.data('text', text)
              relatedInput.prop('checked', calendarCheckbox.prop('checked'))
            });
            this.daytimeChanged(datepicker)
          });
        }
      });

      this.datepickers.push(calendar);
      
      [ calendar_input.parent()].forEach((el)=>{
        el.on('click', ()=>{
          calendar_input.datepicker('show')
        })
      })
    })
    
    $(window).on('resize', (ev)=>{
      this.setupDatepickerMonthCount()
    })
  }

  setupDatepickerMonthCount() {
    let window_width = $(window).width()
    let count = (window_width >= 575) ? 2 : 1

    this.datepickers.forEach((el)=>{
      el.datepicker('option', 'numberOfMonths', [ 1, count ])
    })

  }

  daytimeChanged(datepicker) {
    let datepickerInput = datepicker.input ? datepicker.input : $(datepicker)
    let daypartCheckboxes = datepickerInput.siblings('input[type=checkbox]:checked')
    let text = datepickerInput.val()

    if (daypartCheckboxes.length > 0) {
      daypartCheckboxes.each((i,el)=>{
        text = text + ', ' + $(el).data('text')
      })
    }

    datepickerInput.siblings('.date-daypart').text(text)
  }

  setupAutocomplete() {
    let self = this
    this.form.find('.station-autocompete').autocomplete({
      delay: 500,
      minLength: 2,
      selectFirst: false,
      autoFocus: false,
      source: function (request, response) {
        var term = request.term;
        if(request.term in self.autocompleteCache){
          response(self.autocompleteCache[term]);
          return;
        }
        window._preloader = undefined;
        $.ajax({
          dataType: 'json',
          url: self.autocompleteUrl,
          data: $.param({prefix: request.term, locale: I18n.locale}),
          success: function (data) {
            self.autocompleteCache[term] = data;
            response(data);
          }
        })
      },
      create: function (event, ui) {
        $(this).data('ui-autocomplete')._renderItem = function (ul, item) {
          return $( '<li>' ).addClass('ui-menu-item-wrapper')
            .attr( 'data-value', item.code )
            .append( item.name + (item.city_name ? " (" + item.city_name + ")" : '')  )
            .appendTo( ul );
        };
        $(this).on('click',function(ev){
          ev.preventDefault();
          $(this).trigger('select');
          return false;
        });
      },
      select: function (event, ui) {
        event.preventDefault();
        var element = $(event.target);
        var nextIndex = parseInt(element.attr('tabindex')) + 1;
        element.val(ui.item.name).parents('.js-station').find('.code').val(ui.item.code);
        $('[tabindex=' + nextIndex + ']').trigger('focus');
      },
  
      focus: function (event, ui) {
        this.value = ui.item.name;
        $(this).siblings('.code').val(ui.item.code);
        $('.ui-menu-item.ui-state-active').removeClass('ui-state-active');
        $('.ui-menu-item[data-value="'+ui.item.code+'"]').addClass('ui-state-active');
        return false;
      }
    });
  }

  setupDayPartsEvents() {
    this.form.find('.day-parts').each((i,el) => {
      let dayPartsContainer = $(el)
      dayPartsContainer.data('index', i)
      dayPartsContainer.find('a').on('click', (ev) => {
        let link = $(ev.target)
        ev.preventDefault();
        let datepicker = $(this.datepickers[link.parent().data('index')]).datepicker('setDate', link.data('increment'))
        this.daytimeChanged(datepicker)
      })
    })
  }

  setupDestinantionsToggle() {
    this.form.find('.toggle_destinations').on('click', (ev)=>{
      [
        [this.form.find('#departure'),this.form.find('#arrive')], 
        [this.form.find('.js-from-code'),this.form.find('.js-to-code')]
      ].forEach((el)=>{
        let tmp = el[0].val()
        el[0].val(el[1].val())
        el[1].val(tmp)
      })
    })
  }

  //TODO: -delete setupTabs after checking if it is redundant 

  // setupTabs() {
  //   this.form.find('#nav-tab a').on('click', (ev) => {
  //     let currentTab = $(ev.target)
  //     currentTab.siblings('a').removeClass('active')
  //     currentTab.addClass('active')
  //     let tempTab = $('#calendar-tabpanel-2')
  //     let showTempTab = (currentTab.attr('id') == 'nav-calendar-tab-2')
  //     if (showTempTab) {
  //       tempTab.addClass('active').delay(1).queue(function(next){
  //         $(this).addClass("show");
  //         next();
  //       });
  //     } else {
  //       tempTab.removeClass('show').delay(200).queue(function(next){
  //         $(this).removeClass("active");
  //         next();
  //       });
  //     }
  //     tempTab.find('input').each((i,el)=>{
  //       $(el).attr('disabled', !showTempTab)
  //     });
  //   });
  // }
}

export default SearchForm