/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//


require.context('../images', true)

// import $ from 'jquery';
import  '../src/e-bilet/js/lib/jquery-ui'
import  '../src/e-bilet/js/lib/modernizr-custom'
import 'magnific-popup'
import 'tooltipster'


import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/modal';


import 'jquery-validation/dist/jquery.validate'
import 'jquery-validation/dist/localization/messages_uk'
import 'jquery-ujs'

import '../src/e-bilet/js/lib/renderer'
import '../src/common/lib/store'
import '../src/e-bilet/js/pages/all_page'
import '../src/e-bilet/js/pages/main_page'
import '../src/e-bilet/js/pages/search-results'
import '../src/e-bilet/js/pages/faq'
import '../src/e-bilet/js/pages/direction_page'

import '../src/e-bilet/js/lib/select_component'
import '../src/e-bilet/js/lib/search_form'
import '../src/e-bilet/js/lib/cabinet/actual-filter'
import '../src/e-bilet/js/lib/cabinet/history-filter'
import '../src/e-bilet/js/lib/cabinet/refund-confirm-form'
import '../src/e-bilet/js/lib/contact-form'
import '../src/e-bilet/js/lib/custom-refund-form'
import '../src/e-bilet/js/lib/bus/search_form'

import '../src/e-bilet/js/lib/channels/consumer'
import '../src/e-bilet/js/pages/bus/search-results'
import '../src/e-bilet/js/pages/bus/cabinet'

import '../src/e-bilet/scss/application'