import {Popup} from '../popup'

var storeFactory = function(){
  switch(true){
    case (scenario.isBoryspil()):
      return new BoryspilStore();
      break; 
    case (scenario.isTransfer()):
      return new TransferStore();
      break;
    case scenario.isLocal():
      return new Store();
      break;
    case scenario.isInternational():
      return new SelectorStore();
      break;
    case scenario.isCargo():
      return new CargoStore();
      break;
    default:
      new Popup('attention',I18n.t('try_search_again')).show();
      return false;
  }
};
var Store = function(){
  this.initialize();
};
Store.prototype.initialize = function(){
  this.subscribers  = [];
  this.places       = [];
  this.validation   = {maxPlaces: (scenario.isRoundtrip() ? 1 : 4)};
  this.valid        = this.valid.bind(this);
  this.addPlace     = this.addPlace.bind(this);
  this.deletePlace  = this.deletePlace.bind(this);
  this.findInStore  = this.findInStore.bind(this);
  this.placesEqual  = this.placesEqual.bind(this);
  this.publish      = this.publish.bind(this);
};
Store.prototype.publish = function(method){
  var method = method || 'onStoreChange';
  $.each(this.subscribers, function(index,subscriber){
    if (subscriber && typeof(subscriber[method]) === 'function'){
      subscriber[method]();
    }else{
      console.warn(subscriber, 'no method ' + method);
    }
  });
};
Store.prototype.valid = function(train_number){
  var places = this.groupPlaceBy('train')[train_number];
  if (places && places.length >= this.validation.maxPlaces){
    new Popup('attention',I18n.t('max_' + this.validation.maxPlaces)).show();
    return false;}

  return true;
};
Store.prototype.addPlace = function(placeData){
  if (!this.valid(placeData.train)){
    return false;}
  if (this.findInStore(placeData)){
    return false;}

  this.places.push(placeData);
  this.publish();
  return true;
};
Store.prototype.deletePlace = function(placeData){
  var index = this.findInStore(placeData, true);
  if (index){
    this.places.splice(index, 1);
    this.publish();
    return true;
  }
  return false;
};
Store.prototype.findInStore = function(placeData, getIndex){
  var result = false;
  var self = this;
  $.each(this.places, function(index, storedPlace){
    if (self.placesEqual(storedPlace, placeData)){
      result = getIndex && index || storedPlace;
    }
  });
  return result;
};
Store.prototype.placesEqual = function(placeOne, placeTwo){
  return  placeOne.train == placeTwo.train &&
          placeOne.wagon == placeTwo.wagon &&
          placeOne.place == placeTwo.place;
};
Store.prototype.groupPlaceBy = function(field){
  return this.places.reduce(function(result, place){
    if (!result[place[field]]) result[place[field]] = [];
    result[place[field]].push(place);
    return result;
  },{});
};
Store.prototype.groupByClient = function(field){
  var trains = this.groupPlaceBy('train');
  var clients = [];
  $.each(trains, function(train, places){
    $.each(places, function(index, place){
      if(!clients[index]) clients[index] = [];
      clients[index].push(place);
    });
  });
  return clients;
};

var SelectorStore = function(){this.initialize()};
SelectorStore.prototype.__proto__ = Store.prototype;
SelectorStore.prototype.deletePlace = function(placeData){
  var indexes = [];
  $.each(this.places, function(index, storedPlace){
    if (  storedPlace.train == placeData.train &&
          storedPlace.wagon == placeData.wagon){
      indexes.push(index);
    }
  });
  if (indexes.length > 0){
    this.places.splice(indexes[indexes.length - 1], 1);
    this.publish();
    return true;
  }
  return false;
};

var CargoStore = function(){this.initialize()};
CargoStore.prototype.__proto__ = Store.prototype;

var TransferStore = function(){
  this.initialize();
  this.validation = {maxPlaces: 4};
};
TransferStore.prototype.__proto__ = Store.prototype;
TransferStore.prototype.groupPlaceBy = function(field){
  this.orderPlacesByTrainNumber();
  return Store.prototype.groupPlaceBy.call(this, field);
};
TransferStore.prototype.orderPlacesByTrainNumber = function(){
  var first_train_number = $('form').find("[name='pair[trains[0]][number]']").first().val();
  this.places.sort(function(a,b){
    if (a.train == first_train_number && a.train !== b.train){
      return -1;
    }else if(a.train === b.train){
      return 0;
    }else{
      return 1;
    }
  })
};

var BoryspilStore = function(){
  this.initialize();
  this.validation = {maxPlaces: 9};
};
BoryspilStore.prototype.__proto__ = Store.prototype;
BoryspilStore.prototype.placesEqual = function(placeOne, placeTwo){
  return  placeOne.train == placeTwo.train &&
          placeOne.wagon == placeTwo.wagon &&
          placeOne.type  == placeTwo.type;
};
BoryspilStore.prototype.deletePlace = function(placeData){
  var index = null;
  $.each(this.places, function(_index, storedPlace){
    if (  storedPlace.train == placeData.train &&
          storedPlace.wagon == placeData.wagon){
      index = _index;
    }
  });
  if (index){
    this.places.splice(index, 1);
    this.publish();
    return true;
  }
  return false;
};
BoryspilStore.prototype.addPlace = function(placeData){
  if (!this.valid(placeData.train)){
    return false;}
  
  this.places.push(placeData);
  this.publish();
  return true;
};
export default storeFactory